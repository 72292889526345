
.chatbot p {
    margin-bottom: 1rem;
}
.chatbot a {
    color: blue;
    text-decoration: underline;
}

.chatbot ul,
.chatbot ol
{
    margin-left: 2rem;
}

